/* eslint-disable no-alert */
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(String(text)).then(
    () => {
      alert('Copied to clipboard successfully!');
    },
    () => {
      alert('Unable to write to clipboard. :-(');
    }
  );
};

export default copyToClipboard;
