import React from 'react';

// Style
import './page-information.scss';

const PageInformation = () => (
  <div className="page-information p-3 my-5">
    <h5 className="text-bold mt-3">Zoom Virtual Backgrounds Marketplace</h5>
    <p>
      Chat, doing online meetings, and video conferences with your remote team using Zoom’s robust
      remote conferencing tools. Work together, collaborate, and stay connected with your team
      wherever their location. But this is just the beginning. What happens when we throw in our
      video call backgrounds?
    </p>

    <h5 className="text-bold mt-3">Great virtual background images and videos for zoom</h5>
    <p>
      Use virtual background images to give life and style to your zoom video meetings with your
      team. This allows you to show an image or video as your background during a video meeting.
      Whether you want to hide your messy room during your video meetings or prevent your team from
      seeing your children running around in their pajamas, our zoom video call background feature
      got you covered. Using this feature allows you to set a custom background whenever you’re in a
      conference call.
    </p>

    <h5 className="text-bold mt-3">
      Find (Search for) your favorite zoom virtual background images and videos
    </h5>
    <p>
      Awesome background search feature. Want a background to show that you’re working from the
      beach? Looking for a background that showcases a corporate setting? Or you’re just out there
      looking for one that will go with what you wear. Whatever you want, our search feature allows
      you find the best zoom virtual background images and videos to spice up your conference call.
      Choose from thousands of backgrounds of different colors and different settings and make your
      meeting lively.
    </p>

    <h5 className="text-bold mt-3">Fun and exciting video calls</h5>
    <p>
      Using zoom virtual background images and videos will give you a chance to make meetings
      lively. You can create fun with your background images to make your team excited. Background
      pictures of pets, of your favorite movies or your favorite place can bring excitement or
      curiosity to your team to get to know you more.
    </p>

    <h5 className="text-bold mt-3">Eliminate embarrassing moments during zoom meetings</h5>
    <p>
      Maybe you just woke up. Or you’re in the kitchen cooking. Or probably freeing yourself in the
      washroom. Wherever you are, you can use zoom background images to cover your background and
      save yourself the embarrassment.
    </p>

    <h5 className="text-bold mt-3">Enable zoom virtual background now</h5>
    <ul>
      <li>To enable the zoom virtual background, you should first log into your zoom account.</li>
      <li>
        Click on your profile picture on the top-right corner of your zoom window. Then click
        on“Settings”.
      </li>
      <li>Click on “Virtual Background” in the left menu bar.</li>
      <li>
        Here, you can select from one of zoom’s default virtual backgrounds or upload your own
        background image or video.
      </li>
      <li>
        If you want more options, since Zoom’s default images and videos aren’t many, our virtual
        backgrounds give you access to thousands of royalty-free images and videos to choose from –
        so you can use it to give your background life.
      </li>
    </ul>
    <h5 className="text-blue text-bold mt-3">
      No affiliation with or endorsement by Zoom Video Communications, Inc. is intended or implied.
    </h5>
  </div>
);

export default PageInformation;
