import React, { useRef, useState } from 'react';
import { Download, ShareBox } from 'akar-icons';

// Components
import OverlayScreen from '../OverlayScreen/OverlayScreen';

// Style
import './card-style.scss';
import titleCase from '../../../../utils/titleCase';

const Card = ({
  title,
  description,
  downloadUrl,
  source,
  category,
  sourceUrl,
  previewUrl,
  thumbnail,
  keywords,
  type,
  authorName,
  authorAvatar,
  authorUrl,
}) => {
  const defaultImage =
    'https://res.cloudinary.com/hebu10lmu/image/upload/v1599038752/www/forestry/preview-nf_a82xgf.png';
  const imageRef = useRef(null);

  const [overlay, setOverlay] = useState(false);

  return (
    <div className="custom-virtual-card">
      <OverlayScreen overlayState={overlay} sourceUrl={sourceUrl} setOverlay={setOverlay} />
      <div className="flex p-1-1">
        <div className="author-img-area">
          <img
            className="img-responsive"
            src={
              authorAvatar ||
              'https://avatars.dicebear.com/api/bottts/remoteteamavatar.svg?m=7&b=%23f5f5f5'
            }
            alt={authorName}
            onError={() => {
              imageRef.current.src = defaultImage;
            }}
          />
        </div>
        <div className="content ml-1">
          <h6>{title || titleCase(category)}</h6>
          <p>
            {type === 'image' ? 'Image' : 'Video'} by{' '}
            <a href={authorUrl || sourceUrl}>{authorName || source}</a>
          </p>
        </div>
      </div>
      <div className="flex flex-center flex-middle p-1">
        <div className="img-area">
          <img
            className="img-responsive"
            src={thumbnail || previewUrl || sourceUrl}
            loading="lazy"
            alt={keywords || description || category}
            onError={() => {
              imageRef.current.src = defaultImage;
            }}
            ref={imageRef}
          />
        </div>
      </div>
      <div className="flex flex-between flex-middle card-group" style={{ width: '100%' }}>
        <button
          className="card-button card-button--green p-1"
          type="button"
          onClick={() => setOverlay(!overlay)}
        >
          <ShareBox size={18} />
          <span className="ml-1">Share</span>
        </button>
        <a href={downloadUrl || sourceUrl} className="card-button card-button--blue p-1" download>
          <Download size={18} />
          <span className="ml-1">Download</span>
        </a>
      </div>
    </div>
  );
};

export default Card;
