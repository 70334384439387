import React from 'react';
import PropTypes from 'prop-types';

// Style
import './loadmore-button.scss';

const LoadMoreButton = ({ hasMore, visible, onClick }) =>
  hasMore ? (
    <button
      className={`p-1 my-1 load-more-button text-bold ${visible && 'display-none'}`}
      type="button"
      onClick={onClick}
    >
      Load More
    </button>
  ) : (
    <p>Not more data</p>
  );

LoadMoreButton.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default LoadMoreButton;
