import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronRight, ChevronLeft } from 'akar-icons';

// Style
import './slider-card.scss';

const SliderCard = ({ images, content }) => {
  const [index, setIndex] = useState(0);

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) setIndex(images.length - 1);
    else setIndex(nextIndex);
  };

  return (
    images?.length > 0 && (
      <div className="slider-card flex flex-column flex-center flex-middle">
        <div className="top-area text-center">
          <span>Step {index + 1}</span>
        </div>

        <div className="middle-area p-1">
          <figure className="cover-image" style={{ height: 240 }}>
            <img
              loading="lazy"
              src={images[index]}
              alt={index}
              style={{
                borderRadius: 28,
              }}
            />
          </figure>
          <p>{content[index]}</p>
        </div>

        <div className="bottom-area flex flex-middle flex-between p-1">
          <button type="button" onClick={slideLeft}>
            <ChevronLeft color="#2D46C1" size={18} />
          </button>
          <div className="container">
            {images.map((_, imageIndex) => (
              <button
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={imageIndex}
                onClick={() => setIndex(imageIndex)}
                className="slide-radio-button"
              >
                <svg
                  className={`${imageIndex === index ? 'active' : ''}`}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#a4adeb"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 0C2.68548 0 0 2.68548 0 6C0 9.31452 2.68548 12 6 12C9.31452 12 12 9.31452 12 6C12 2.68548 9.31452 0 6 0Z"
                    fill="#a4adeb"
                  />
                </svg>
              </button>
            ))}
          </div>
          <button type="button" onClick={slideRight}>
            <ChevronRight color="#2D46C1" size={18} />
          </button>
        </div>
      </div>
    )
  );
};

SliderCard.defaultProps = {
  content: '',
};

SliderCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array,
};

export default SliderCard;
