import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';

// Components
import SEO from '../../components/SEO/SEO';
import Micro from '../../layouts/Micro';
import RefinementList from '../../components/Discovery/RefinementList/RefinementList';
import SearchBox from '../../components/Discovery/SearchBox/SearchBox';
import SliderCard from './components/SliderCard/SliderCard';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/CustomBreadcrumb';
import Card from './components/Card/Card';
import LoadMoreButton from './components/LoadMoreButton/LoadMoreButton';
import PageInformation from './components/PageInformation/PageInformation';

// Utils
import titleCase from '../../utils/titleCase';

const ZoomVirtualBackgrounds = ({ data, location }) => {
  const { edges } = data.allVirtualBackgroundsJson;

  // All Data
  const [originalData] = useState(edges);
  const [backgroundsData, setBackgroundsData] = useState([...originalData.slice(0, 30)]);

  // ==== Load More Feature ====

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);

  // State for button visibility
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);

  // State of whether there is more to load
  const [hasMore, sethasMore] = useState(originalData.length > 30);

  // Load more button click
  const handleLoadMore = () => setLoadMore(true);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = backgroundsData.length;
      const isMore = currentLength < originalData.length;
      const nextResults = isMore ? originalData.slice(currentLength, currentLength + 30) : [];
      setBackgroundsData([...backgroundsData, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  // Check if there is more
  useEffect(() => {
    const isMore = backgroundsData.length < originalData.length;
    sethasMore(isMore);
  }, [backgroundsData]);

  // ==== Load More Feature ====

  // Search
  const [query, setQuery] = useState();

  const onChange = async (searchTerm) => {
    const filtered = originalData.filter(({ node }) =>
      node.title
        ? node.title.toLowerCase().includes(searchTerm.toLowerCase())
        : node.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setQuery(searchTerm);
    if (searchTerm === '') {
      setBackgroundsData([...originalData.slice(0, 30)]);
      setLoadMoreVisible(false);
    } else {
      setBackgroundsData(filtered);
      setLoadMoreVisible(true);
    }
  };

  // Types
  const types = [...new Set(edges.map(({ node }) => node.type).filter(Boolean))].map((type) => ({
    title: titleCase(type),
    slug: type,
  }));

  // Categories
  const categories = [...new Set(edges.map(({ node }) => node.category).filter(Boolean))].map(
    (category) => ({
      title: titleCase(category),
      slug: category.replace(/ +/, '-'),
    })
  );

  // Slider Card
  const images = [
    'https://res.cloudinary.com/hebu10lmu/image/upload/v1599038400/www/forestry/step-one_xffttn.png',
    'https://res.cloudinary.com/hebu10lmu/image/upload/v1599038400/www/forestry/step-two_sksmic.png',
    'https://res.cloudinary.com/hebu10lmu/image/upload/v1599038400/www/forestry/step-three_ym4tdo.png',
    'https://res.cloudinary.com/hebu10lmu/image/upload/v1599038400/www/forestry/step-four_xf5a4n.png',
  ];

  // Slider Card
  const slideContent = [
    'For adding backgrounds, start a video call.',
    'Click the arrow that right hand side of Stop Video icon and click Choose Virtual Background',
    'Click Add video and select the video that you want in the background.',
    'Now you settled! Enjoy your meeting and new background.',
  ];

  // Sidebar Area
  const [typeState, setTypeState] = useState({
    activeItem: '',
    isClicked: false,
  });

  const [categoryState, setCategoryState] = useState({
    activeItem: '',
    isClicked: false,
  });

  const onChangeType = (prevItem, selectedType) => {
    const prevSelectedItem = '' || prevItem[1];

    const filtered = originalData
      .filter(({ node }) => node.type.includes(selectedType))
      .slice(0, 30);
    setBackgroundsData(filtered);

    if (!typeState.isClicked) {
      setTypeState({ isClicked: true, activeItem: selectedType });
      setBackgroundsData(filtered);
      setLoadMoreVisible(true);
    } else if (prevSelectedItem !== selectedType) {
      setTypeState({ isClicked: true, activeItem: selectedType });
      setBackgroundsData(filtered);
      setLoadMoreVisible(true);
    } else {
      setTypeState({ isClicked: false, activeItem: null });
      setBackgroundsData([...originalData.slice(0, 30)]);
      setLoadMoreVisible(false);
    }
  };

  const onChangeCategory = (prevItem, selectedCategory) => {
    const prevSelectedItem = '' || prevItem[1];

    const filtered = originalData
      .filter(({ node }) => node.category.includes(selectedCategory))
      .slice(0, 30);
    setBackgroundsData(filtered);

    if (!categoryState.isClicked) {
      setCategoryState({ isClicked: true, activeItem: selectedCategory });
      setBackgroundsData(filtered);
    } else if (prevSelectedItem !== selectedCategory) {
      setCategoryState({ isClicked: true, activeItem: selectedCategory });
      setBackgroundsData(filtered);
      setLoadMoreVisible(true);
    } else {
      setCategoryState({ isClicked: false, activeItem: null });
      setBackgroundsData([...originalData.slice(0, 30)]);
      setLoadMoreVisible(false);
    }
  };

  return (
    <Micro>
      <SEO title="Zoom Virtual Backgrounds" />
      <section style={{ backgroundColor: '#EEF7F9' }}>
        <div className="container py-3">
          <div className="row flex-middle">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
              <CustomBreadcrumb location={location} />
              <h2 style={{ width: '90%' }}>Zoom Virtual Backgrounds Marketplace</h2>
              <p className="text-muted" style={{ width: '90%' }}>
                Are you looking for virtual meeting backgrounds for Zoom? Check-out our unique
                collection of zoom images and find the best one that suits you!
              </p>
            </div>
            <div className="col-lg-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 flex flex-center">
              <SliderCard images={images} content={slideContent} />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-1 col-md-6 col-sm-6 col-xs-6" style={{ height: '100%' }}>
            <div className="row mb-1">
              <RefinementList
                menuTitle="Type"
                data={types}
                onSelect={onChangeType}
                activeItem={typeState.activeItem}
                isVertical
              />
            </div>

            <div className="row">
              <RefinementList
                className="mt-2"
                menuTitle="Categories"
                data={categories}
                onSelect={onChangeCategory}
                activeItem={categoryState.activeItem}
                isVertical
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <SearchBox placeholder="Search for backgrounds" query={query} onChange={onChange} />
              </div>
            </div>
            <div className="row">
              {backgroundsData.map(({ node }) => (
                <div
                  key={`_${Math.random().toString(36).substr(2, 9)}`}
                  className="col-lg-2 col-md-3 col-sm-6 col-xs-6"
                >
                  <Card
                    title={node.title}
                    description={node.description}
                    downloadUrl={node.download_url}
                    category={node.category}
                    sourceUrl={node.source_url}
                    previewUrl={node.preview_url}
                    thumbnail={node.thumbnail}
                    keywords={node.keywords}
                    source={node.source}
                    authorName={node.author_name}
                    authorAvatar={node.author_avatar}
                    authorUrl={node.author_url}
                    type={node.type}
                  />
                </div>
              ))}
            </div>
            {/* Load More Button */}
            <div className="row flex flex-center flex-middle">
              <LoadMoreButton
                hasMore={hasMore}
                visible={loadMoreVisible}
                onClick={handleLoadMore}
              />
            </div>
            {/* Load More Button */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <PageInformation />
          </div>
        </div>
      </div>
    </Micro>
  );
};

export default ZoomVirtualBackgrounds;

export const appMarketPageQuery = graphql`
  {
    allVirtualBackgroundsJson(filter: { Status: { eq: "Approved" } }) {
      edges {
        node {
          title
          type
          thumbnail
          source_url
          source
          preview_url
          keywords
          download_url
          description
          category
          author_url
          author_name
          author_avatar
        }
      }
    }
  }
`;
