import React from 'react';

// Icons
import {
  FacebookFill,
  TwitterFill,
  LinkedInFill,
  TelegramFill,
  PinterestFill,
  RedditFill,
  Copy,
  CircleX,
} from 'akar-icons';

// Style
import './overlay-screen.scss';

// Utils
import copyToClipboard from '../../../../utils/copyToClipboard';

const OverlayScreen = ({ overlayState, sourceUrl, setOverlay }) => (
  <div className={`overlay ${overlayState ? '' : 'display-none'}`}>
    <button type="button" className="close-icon" onClick={() => setOverlay(!overlayState)}>
      <CircleX size={24} color="#2d46c1" />
    </button>
    <ul className="flex p-1 flex-center flex-middle flex-wrap">
      <li>
        <a itemProp="url" href={`https://www.facebook.com/sharer.php?u=${sourceUrl}`}>
          <FacebookFill size={38} color="white" />
        </a>
      </li>
      <li>
        <a itemProp="url" href={`https://twitter.com/intent/tweet?url=${sourceUrl}`}>
          <TwitterFill size={38} color="white" />
        </a>
      </li>
      <li>
        <a itemProp="url" href={`https://www.linkedin.com/shareArticle?url=${sourceUrl}`}>
          <LinkedInFill size={38} color="white" />
        </a>
      </li>
      <li>
        <a itemProp="url" href={`https://www.pinterest.com/pin/create/button?url=${sourceUrl}`}>
          <PinterestFill size={38} color="white" />
        </a>
      </li>
      <li>
        <a itemProp="url" href={`https://reddit.com/submit?url=${sourceUrl}`}>
          <RedditFill size={38} color="white" />
        </a>
      </li>

      <li>
        <a itemProp="url" href={`https://telegram.me/share/url?url=${sourceUrl}`}>
          <TelegramFill size={38} color="white" />
        </a>
      </li>
      <li>
        <button type="button" onClick={() => copyToClipboard(sourceUrl)}>
          <Copy size={38} color="white" />
        </button>
      </li>
    </ul>
  </div>
);

export default OverlayScreen;
